@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'lina-soft';
  src: local(''), url('/fonts/linasoft/regular.woff') format('woff'),
    url('/fonts/linasoft/regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'league-spartan';
  src: local(''),
    url('/fonts/league-spartan/static/LeagueSpartan-Regular.woff')
      format('woff'),
    url('/fonts/league-spartan/static/LeagueSpartan-Regular.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'sombra';
  src: local(''), url('/fonts/Sombra/Sombra-Bold.woff') format('woff'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/fonts/Sombra/Sombra-Bold.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-weight: normal;
  font-style: bold;
  font-display: swap;
}
@font-face {
  font-family: 'sombra';
  src: local(''), url('/fonts/Sombra/Sombra-Medium.woff') format('woff'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/fonts/Sombra/Sombra-Medium.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-weight: normal;
  font-style: medium;
  font-display: swap;
}
@font-face {
  font-family: 'ping';
  src: local(''), url('/fonts/Ping/Medium.woff') format('woff'),
    url('/fonts/Ping/Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: medium;
  font-display: swap;
}
@font-face {
  font-family: 'ping';
  src: local(''), url('/fonts/Ping/Bold.woff') format('woff'),
    url('/fonts/Ping/Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: bold;
  font-display: swap;
}
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-50;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-200;
    border-radius: 100vh;
    @apply border;
    @apply border-2;
    @apply border-gray-100;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.styles_container__2pRR5 {
  @apply bg-primary-100 !important;
}

.styles_container__2pRR5 .styles_continues__3Yx6D {
  @apply bg-primary-500 !important;
}

.address-root {
  background-image: url('/images/top-mask.svg'), url('/images/bottom-mask.svg');
  background-repeat: no-repeat;
  background-position: top right, left bottom;
  background-size: 320px auto, 280px auto;
}

@media only screen and (min-width: 750px) {
  .address-root {
    background-size: 480px auto, 450px auto;
  }
}

/* swiper style  */
.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  text-align: center;
  font-size: 12px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-wrapper {
  align-items: flex-start;
}

.swiper-button {
  position: absolute;
  top: 35%;
  z-index: 10;
  cursor: pointer;
}
.swiper-button svg {
  width: 1.5rem;
  height: 1.5rem;
}
.image-swiper-button-prev {
  @apply text-primary-500;
  left: 0px;
  top: 44%;
}
.image-swiper-button-next {
  @apply text-primary-500;
  right: 0px;
  top: 44%;
}

.swiper-button-disabled {
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  @apply !bg-primary-500;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullets {
  margin-bottom: -15px !important;
}

#card-element {
  border-radius: 7px;
  padding: 12px;
  border: 1px solid #f4f4f4;
  max-height: 44px;
  width: 100%;
  background: #f4f4f4;
  box-sizing: border-box;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

/* NProgress */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply fixed top-0 left-0 w-full bg-primary-500;
  z-index: 1031;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  @apply absolute right-0 block h-full opacity-100;
  width: 100px;
  box-shadow: 0 0 10px primary, 0 0 2px primary;
  transform: rotate(3deg) translate(0px, -4px);
}

/*google maps info window style*/

.gm-style .gm-style-iw-c {
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: black;
  color: white;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
  padding: 0 5px 10px 10px !important;
}

.gm-style .gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-tc::after {
  background-color: black !important;
}

.new-address div[data-testid='close-btn'] {
  display: none;
}

.hdpi .pac-icon {
  background-image: url('/images/search-pin.svg');
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100%;
}
.pac-item {
  padding: 6px 4px;
}
.pac-logo:after {
  display: none;
}
html:lang(ar) .pac-container {
  direction: rtl;
}
html:lang(en) .pac-container {
  direction: ltr;
}
html:lang(en) .pac-container .pac-item {
  width: 100%;
  text-align: left;
}
html:lang(ar) .pac-container .pac-item {
  width: 100%;
  text-align: right;
}
